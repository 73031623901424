import { useEffect, useState } from "react";
import { isNil } from "lodash-es";
import { FormVersion } from "../types/FormVersion";
import useAuth from "../hooks/useAuth";
import useDatabase from "../hooks/useDatabase";
import { FormState, SubmissionStoreOptions } from "./useSubmissionStore";
import useDeviceInfo from "../hooks/useDeviceInfo";
import useActionSetDb from "./useActionSetDb";
import useActionLoadForm from "./useActionLoadForm";
import useUserId from "../hooks/useUserId";

const defaultOptions: SubmissionStoreOptions = { readOnly: false, persist: true, validate: true };

export const useSubmissionStoreInitializer = (
  submissionId?: string,
  formId?: string,
  formState?: FormState, // only to set up initially, after which it will be managed in zustand
  formVersion?: FormVersion,
  options: SubmissionStoreOptions = defaultOptions,
): { initialized: boolean } => {
  const { username } = useAuth();
  const userId = useUserId();
  const { database } = useDatabase();
  const { id: deviceId } = useDeviceInfo();
  const loadForm = useActionLoadForm();
  const setDb = useActionSetDb();
  const [loaded, setLoaded] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!isNil(database)) {
      setDb(database);
    }
  }, [database, setDb]);

  useEffect(() => {
    if (loaded && !reload) {
      return; // don't run twice, unless we want to 'reload'. Once loaded, we're done (unless initial formState is updated via "Save & New")
    }
    if (isNil(formState) || isNil(formVersion) || isNil(submissionId) || isNil(formId)) {
      return;
    }

    const formStateMatchesSubmission = isNil(formState.fields.find((f) => f.value.meta.submissionId === submissionId));
    // should continue if the form has no fields
    if (formState.fields.length !== 0 && formStateMatchesSubmission) {
      return; // because formState is created async, there's a short window where "formState" is outdated when switching submissionId (Save & New)
    }

    // fill our zustand store with all required state to run the FormEngine
    loadForm(formVersion, submissionId, deviceId, formId, userId, username, options, formState);

    setLoaded(true);
    setReload(false);
  }, [formState, formVersion, submissionId, formId, username, options, loadForm, deviceId, loaded, userId, reload]);

  useEffect(() => {
    // make sure to reload when initial formState changed, which currently only happens on "Save & New"
    // NOTE: determining initial formState is an async process in useInitialFormState, so this change comes in AFTER updating the submissionId, options, etc
    if (loaded && !isNil(formState)) {
      setReload(true);
    }
  }, [loaded, formState]);

  return { initialized: loaded };
};

import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import useFormVersion from "../hooks/useFormVersion";
import SubmissionHistoryPreviewDrawer from "./SubmissionHistoryPreviewDrawer";
import RecentSubmissions from "./search/RecentSubmissions";
import SearchSubmissionList, { SearchSubmissionPayload } from "./search/SearchSubmissionList";
import useOnlineStatus from "../hooks/useOnlineStatus";
import OfflineContent from "../pages/errorpages/OfflineContent";
import SearchSubmissionsForm from "./search/SearchSubmissionsForm";
import { DrawerFooterProps } from "../storybook/components/Drawer/DrawerFooter";
import { Spinner } from "../storybook/components/Spinner/Spinner";
import { Drawer } from "../storybook/components/Drawer/Drawer";
import { Segment } from "../storybook/components/Segment/Segment";
import { hasEmptyValue } from "../utils/formUtil";
import { fieldToRemote, fieldStateToField } from "../types/Field";
import useStateFields from "../state/useStateFields";

type SearchSubmissionDrawerProps = {
  formVersionId: string | undefined;
  formId: string | undefined;
  open: boolean;
  onClose: () => void;
};
type Mode = "recent" | "search";

const SubmissionHistoryDrawer: FC<SearchSubmissionDrawerProps> = ({ formId, formVersionId, open, onClose }) => {
  const { t } = useTranslation();
  const { customerId } = useAuth();
  const { formVersion, isFetchingFormVersion, refetchFormVersion } = useFormVersion(formVersionId, customerId, formId);
  const { isOnline } = useOnlineStatus();
  const [previewSubmissionId, setPreviewSubmissionId] = useState<string>();
  const [mode, setMode] = useState<Mode>("recent");
  const [searchSubmissionPayload, setSearchSubmissionPayload] = useState<SearchSubmissionPayload>();
  const searchFieldsState = useStateFields();

  const handleOnClose = useCallback((): void => {
    onClose();
    setMode("recent");
    setSearchSubmissionPayload(undefined);
  }, [onClose]);

  const footer = (): DrawerFooterProps | undefined => {
    if (!isOnline || mode !== "search") {
      return undefined;
    }

    return searchSubmissionPayload
      ? {
          kind: "default",
          primaryButton: {
            label: t("CLEAR"),
            onClick: () => setSearchSubmissionPayload(undefined),
          },
        }
      : {
          kind: "default",
          primaryButton: {
            label: t("SEARCH_SUBMISSIONS_BUTTON"),
            onClick: () =>
              setSearchSubmissionPayload({
                customerId,
                formId,
                fields: searchFieldsState
                  .filter((fieldState) => !hasEmptyValue(fieldState.value.rawValue))
                  .map(fieldStateToField)
                  .map(fieldToRemote),
              }),
          },
          secondaryButton: {
            label: t("CLEAR"),
            onClick: async (): Promise<void> => {
              await refetchFormVersion();
              setSearchSubmissionPayload(undefined);
            },
          },
        };
  };

  const clearPreviewSubmissionId = (): void => setPreviewSubmissionId(undefined);

  const isLoading = isFetchingFormVersion || !formVersion || !customerId || !formId || !formVersionId;

  return (
    <Drawer
      header={{
        kind: "simple",
        title: t("SEARCH_SUBMISSIONS"),
        button: { kind: "icon", icon: "XIcon", onClick: handleOnClose },
        content: formVersion?.settings.searchSettings?.enabled ? (
          <Segment
            className="mx-5 mb-6"
            selected={mode}
            onSelect={(id) => setMode(id as Mode)}
            options={[
              { id: "recent", label: t("HISTORY_RECENT") },
              { id: "search", label: t("HISTORY_SEARCH") },
            ]}
          />
        ) : undefined,
      }}
      footer={footer()}
      open={open}
      onClose={handleOnClose}
      contentPadding={false}
    >
      {!isOnline && <OfflineContent centered />}
      {isOnline && isLoading && <Spinner />}
      {isOnline && !isLoading && (
        <>
          {mode === "recent" && (
            <RecentSubmissions
              formId={formId}
              formVersionId={formVersionId}
              customerId={customerId}
              onClick={setPreviewSubmissionId}
            />
          )}
          {mode === "search" && (
            <>
              {!searchSubmissionPayload && <SearchSubmissionsForm formVersion={formVersion} />}
              {searchSubmissionPayload && (
                <SearchSubmissionList
                  searchSubmissionPayload={searchSubmissionPayload}
                  onClick={setPreviewSubmissionId}
                />
              )}
            </>
          )}
          {previewSubmissionId && (
            <SubmissionHistoryPreviewDrawer id={previewSubmissionId} onClose={clearPreviewSubmissionId} />
          )}
        </>
      )}
    </Drawer>
  );
};

export default SubmissionHistoryDrawer;
